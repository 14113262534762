import React, { Component } from "react";
import Festival from "./Festival";

class Festivals extends Component {
  constructor(props) {
    super(props);
    this.headerContainer = React.createRef();
    this.state = {
      loading: true,
      festivals: []
    };
  }

  componentDidMount() {
    this.fetchPostData();
  }

  fetchPostData() {
    fetch(
      `https://westberkscamra.org.uk/wp-json/wp/v2/festivals?orderby=start_date&order=asc`
      // `http://westberksnew.local/wp-json/wp/v2/festivals?orderby=start_date&order=asc`
    )
      .then(response => response.json())
      .then(myJSON =>
        myJSON.map(festival => ({
          id: `${festival.id}`,
          title: `${festival.title.rendered}`,
          start_date: `${festival.start_date}`,
          end_date: `${festival.end_date}`
        }))
      )
      .then(festivals =>
        this.setState({
          festivals,
          loading: false
        })
      )
      .catch(error => console.log("Parsing error", error));
  }

  render() {
    const { festivals, loading } = this.state;
    var official_logo = require("./images/CAMRA-Emerald-Green-top-2019.png");

    return (
      <div>
        <div className="official-logo-top-right">
          <img alt="CAMRA branding" src={official_logo} />
        </div>
        
        <div className="page-header" ref={this.headerContainer}>
          <h2>Festivals</h2>
          <p className="loading">{loading ? "Loading..." : ""}</p>
        </div>

        <div className="std-page">
          <div className="list-container">
            {festivals.map(festival => {
              return (
                <Festival
                  key={festival.id}
                  id={festival.id}
                  title={festival.title}
                  start_date={festival.start_date}
                  end_date={festival.end_date}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Festivals;
