import React from "react";
import "./styles.css";
import Nav from "./Nav";
import About from "./About";
import Home from "./Home";
import Events from "./Events";
import EventDetail from "./EventDetail";
import Festivals from "./Festivals";
import FestivalDetail from "./FestivalDetail";
import Footer from "./Footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <Nav />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/events" component={Events} />
          <Route path="/festivals" component={Festivals} />
          <Route path="/festival/:id" component={FestivalDetail} />
          <Route path="/about" component={About} />
          <Route path="/event/:id" component={EventDetail} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
