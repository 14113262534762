import React from "react";

function DateIcon(props) {
  var has_date = props.date !== "";

  return (  
    has_date ? (
    <div className="post-date">
      <span className="date-num">{props.date.substr(6, 2)}</span>
      <span className="month">
        {new Intl.DateTimeFormat("en-GB", {
          month: "short"
        }).format(
          new Date(
            props.date.substr(0, 4) +
              "-" +
              props.date.substr(4, 2) +
              "-" +
              props.date.substr(6, 2)
          )
        )}
      </span>
      <span className="day-of-week">
        {new Intl.DateTimeFormat("en-GB", {
          weekday: "short"
        }).format(
          new Date(
            props.date.substr(0, 4) +
              "-" +
              props.date.substr(4, 2) +
              "-" +
              props.date.substr(6, 2)
          )
        )}
      </span>
      <span className="year">{props.date.substr(0, 4)}</span>
      </div>
      ) : ("")
    
  );
}

export default DateIcon;
