import React, { Component } from "react";

class About extends Component {
  render() {
    var official_logo = require("./images/CAMRA-Emerald-Green-top-2019.png");

    return (
      <div>
        <div className="official-logo-top-right">
          <img alt="CAMRA branding" src={official_logo} />
        </div>

        <div className="page-header" ref={this.headerContainer}>
          <h2>About</h2>
        </div>

        <div className="std-page">
          <div className="std-container">
            <p>
              The Campaign for Real Ale (CAMRA) is an organisation dedicated to
              fighting for the preservation of traditional British beer and
              traditional British pubs.
            </p>

            <p>
              Once described as ‘the most successful consumer pressure group in
              Western Europe’, the Campaign was formed by 4 journalists in 1971
              who were horrified at the declining quality of British beer.
            </p>

            <p>
              The total membership of CAMRA has topped 188,000 members. These
              members are located throughout the United Kingdom organised into
              more than 180 branches similar to West Berkshire CAMRA.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
