import React, { Component } from "react";

class Home extends Component {
  render() {
    var logo = require("./images/CAMRA-Roundel-Black-20191.png");
    var official_logo = require("./images/CAMRA-Emerald-Green-top-2019.png");

    return (
      <div>
        <div className="official-logo-top-right">
          <img alt="CAMRA branding" src={official_logo} />
        </div>

        <div className="page-header" ref={this.headerContainer}>
          <h2>Home</h2>
        </div>

        <div className="std-page">
          <div className="std-container">
            <img className="logo-image" alt="CAMRA logo" src={logo} />
            <p>
              Welcome to the BETA version of the West Berkshire mobile
              application.
            </p>
            <p>
              Please use the navigation links in the header to access real-time
              data from our website.
            </p>
            <p>
              <strong>
                Data, displays and menu items may change during this Beta phase
                of the application.
              </strong>
            </p>
            <p className="signature">Tony Girling</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
