import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCalendarDay,
  faInfo,
  faBeer
} from "@fortawesome/free-solid-svg-icons";

function Nav() {

  return (
    <nav>
      <ul className="nav-links">
        <Link to="/">
          <li>
            <FontAwesomeIcon icon={faHome} />
            <div className="link-text">HOME</div>
          </li>
        </Link>
        <Link to="/events">
          <li>
            <FontAwesomeIcon icon={faCalendarDay} />
            <div className="link-text">EVENTS</div>
          </li>
        </Link>
        <Link to="/festivals">
          <li>
            <FontAwesomeIcon icon={faBeer} />
            <div className="link-text">FESTIVALS</div>
          </li>
        </Link>
        <Link to="/about">
          <li>
            <FontAwesomeIcon icon={faInfo} />
            <div className="link-text">ABOUT</div>
          </li>
        </Link>
      </ul>
    </nav>
  );
}

export default Nav;
