import React, { Component } from "react";
import DateIcon from "./DateIcon";

class FestivalDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      festival: null
    };
  }

  componentDidMount() {
    this.fetchFestival(this.props.match.params.id);
  }

  fetchFestival(festivalId) {
    fetch(
      `https://westberkscamra.org.uk/wp-json/wp/v2/festivals/${festivalId}`
      // `http://westberksnew.local/wp-json/wp/v2/festivals/${festivalId}`
    )
      .then(response => response.json())
      .then(festival =>
        this.setState({
          festival
        })
      )
      .catch(error => console.log("Parsing error", error));
  }

  render() {
    const { festival, loading } = this.state;
    var official_logo = require("./images/CAMRA-Emerald-Green-top-2019.png");
    var logo = require("./images/CAMRA-Roundel-Black-20191.png");
    var header_classes = (festival && festival.end_date !== "" ? "page-header two-dates" : "page-header");

    return (
      <div>
        {festival ? (
          <div>
            <div className="official-logo-top-right">
              <img alt="CAMRA branding" src={official_logo} />
            </div>

            <div className={header_classes} ref={this.headerContainer}>
              <h2
                dangerouslySetInnerHTML={{ __html: festival.title.rendered }}
              ></h2>
              <p className="loading">{loading ? "Loading..." : ""}</p>
            </div>

            <div className="std-page">
              <div className="date-wrapper stand-alone-date">
                <DateIcon date={festival.start_date} />
              </div>
              <div className="date-wrapper second-date stand-alone-date">
                <DateIcon date={festival.end_date} />
              </div>
              <div className="std-container">
                {festival.is_camra_festival ? (
                  <div className="camra-festival-notice">
                    <img className="logo-image" alt="CAMRA logo" src={logo} />
                    <p>This is a CAMRA organised festival</p>
                    <p>By {festival.organiser}</p>
                  </div>
                ) : (
                  ""
                )}
                <dl>
                  <dt>Location</dt>
                  <dd>{festival.location}</dd>
                  <dt>Address</dt>
                  <dd>{festival.address}</dd>
                </dl>

                <div
                  dangerouslySetInnerHTML={{
                    __html: festival.content.rendered
                  }}
                ></div>
              </div>
            </div>
          </div>
        ) : (
          "Loading..."
        )}
      </div>
    );
  }
}

export default FestivalDetail;
