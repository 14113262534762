import React from "react";

function Footer() {

  return (
    <div className="footer">
     <h3>West Berks CAMRA</h3>
    </div>
  );
}

export default Footer;
