import React from "react";
import DateIcon from "./DateIcon";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

function Event(props) {
  return (
    <Link className="linked-list-item" to={`/event/${props.id}`}>
      <div className="list-item">
        <div className="date-wrapper">
          <DateIcon date={props.event_date}/>
        </div>
        <div className="eventDetails">
          <div
            className="list-item-title"
            dangerouslySetInnerHTML={{ __html: props.title }}
          ></div>
          <div className="item-date">
            {props.event_time.substr(0, 5)}{" "}
            {new Intl.DateTimeFormat("en-GB", {
              year: "numeric",
              month: "short",
              day: "2-digit",
              weekday: "short"
            }).format(
              new Date(
                props.event_date.substr(0, 4) +
                  "-" +
                  props.event_date.substr(4, 2) +
                  "-" +
                  props.event_date.substr(6, 2)
              )
            )}
          </div>
        </div>
        <div className="next-indicator">
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
    </Link>
  );
}

export default Event;
