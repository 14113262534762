import React, { Component } from "react";
import DateIcon from "./DateIcon";

class EventDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      event: null
    };
  }

  componentDidMount() {
    this.fetchEvent(this.props.match.params.id);
  }

  fetchEvent(eventId) {
    fetch(
      `https://westberkscamra.org.uk/wp-json/wp/v2/events/${eventId}`
      // `http://westberksnew.local/wp-json/wp/v2/events/${eventId}`
      )
      .then(response => response.json())
      .then(event =>
        this.setState({
          event
        })
      )
      .catch(error => console.log("Parsing error", error));
  }

  render() {
    const { event, loading } = this.state;

    // console.dir(event);
    // const { events, loading } = this.state;
    var official_logo = require("./images/CAMRA-Emerald-Green-top-2019.png");

    return (
      <div>
        {event ? (
          <div>
            <div className="official-logo-top-right">
              <img alt="CAMRA branding" src={official_logo} />
            </div>

            <div className="page-header" ref={this.headerContainer}>
              <h2
                dangerouslySetInnerHTML={{ __html: event.title.rendered }}
              ></h2>
              <p className="loading">{loading ? "Loading..." : ""}</p>
            </div>

            <div className="std-page">
              <div className="date-wrapper stand-alone-date">
                <DateIcon date={event.event_date} />
              </div>
              <div className="std-container">
                <dl>
                  <dt>Time</dt>
                  <dd>{event.event_time.substr(0, 5)}</dd>
                  <dt>Location</dt>
                  <dd>{event.event_location}</dd>
                  <dt>Address</dt>
                  <dd>{event.event_location_address}</dd>
                  <dt>Postcode</dt>
                  <dd>{event.event_postcode}</dd>
                </dl>

                <div
                  dangerouslySetInnerHTML={{ __html: event.content.rendered }}
                ></div>
              </div>
            </div>
          </div>
        ) : (
          "Loading..."
        )}
      </div>
    );
  }
}

export default EventDetail;
