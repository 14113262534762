import React, { Component } from "react";
import Event from "./Event";

class Events extends Component {
  constructor(props) {
    super(props);
    this.headerContainer = React.createRef();
    this.state = {
      loading: true,
      events: []
    };
  }

  componentDidMount() {
    this.fetchPostData();
  }

  fetchPostData() {
    fetch(
      `https://westberkscamra.org.uk/wp-json/wp/v2/events?orderby=event_date&order=asc`
      // `http://westberksnew.local/wp-json/wp/v2/events?orderby=event_date&order=asc`
    )
      .then(response => response.json())
      .then(myJSON =>
        myJSON.map(event => ({
          id: `${event.id}`,
          title: `${event.title.rendered}`,
          event_date: `${event.event_date}`,
          event_time: `${event.event_time}`
        }))
      )
      .then(events =>
        this.setState({
          events,
          loading: false
        })
      )
      .catch(error => console.log("Parsing error", error));
  }

  render() {
    const { events, loading } = this.state;
    var official_logo = require("./images/CAMRA-Emerald-Green-top-2019.png");

    return (
      <div>
        <div className="official-logo-top-right">
          <img alt="CAMRA branding" src={official_logo} />
        </div>
        
        <div className="page-header" ref={this.headerContainer}>
          <h2>Events</h2>
          <p className="loading">{loading ? "Loading..." : ""}</p>
        </div>

        <div className="std-page">
          <div className="list-container">
            {events.map(event => {
              return (
                <Event
                  key={event.id}
                  id={event.id}
                  title={event.title}
                  event_date={event.event_date}
                  event_time={event.event_time}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Events;
